import React from 'react';
import {
    Button,
    Container,
    Grid,
    Link,
    Typography,
    makeStyles
} from '@material-ui/core';

import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import MusicNoteIcon from '@material-ui/icons/MusicNote';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        maxWidth: "1600px",
        minHeight: '100%',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly', //space-around
        overflow: 'hidden',
        backgroundColor: '#ffffff'
    },
    gridLeft: {
        padding: "40px !important"
    },
    gridRight: {
        padding: "40px 40px 40px 0 !important",

        "& div.image-top": {
            paddingBottom: "35px"
        },
        '@media (max-width:600px)': {
            padding: "0 40px 40px 0 !important",
        }
    },
    boxLeft: {
        maxWidth: "440px",
        minHeight: "565px",
        padding: "40px 40px 0 40px",
        backgroundColor: "#F0F0F0",
        textAlign: 'center',
        '@media (max-width:600px)': {
            minHeight: "50%",
            marginBottom: '0',
            paddingBottom: '0'
        }
    },
    boxRight: {
        maxWidth: "920px",
        minHeight: "263px",
        '@media (max-width:600px)': {
            margin: "0 40px 0 40px",
            padding: '0',
            width: '90%'
        }
    },
    rightTop: {
        backgroundColor: "#EBEBEB",
        marginBottom: '35px',
        padding: '20px'
    },
    rightBottom: {
        backgroundColor: "#CCCED0",
        padding: '20px'
    },
    homeButton: {
        backgroundColor: "#8493AF",
        color: "#ffffff"
    },
    imgShirt: {
        width: "70%", height: "70%", border: "1px solid #666", boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.2)"
    }
})); //920 X 263


const MainView = () => {
  const classes = useStyles();
  const preventDefault = (event) => event.preventDefault();
  
  return (
    <Container maxWidth="sm" className={classes.root}>
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6} lg={4} xl={4} className={classes.gridLeft}>
                <div className={classes.boxLeft}>
                    <div>
                        <img src="images/shirt-1.jpg" className={classes.imgShirt} />
                    </div>

                    <Typography variant="h1" component="h2" style={{ paddingTop: "20px", fontWeight: "700" }}>
                        PRINTED COLLECTION
                    </Typography>

                    <div style={{padding: "20px"}}>
                        {/* <Button className={classes.homeButton}>Shop Now</Button> */}
                    </div>
                </div>
            </Grid>

            <Grid item xs={12} sm={6} lg={8} xl={8} className={classes.gridRight}>
                <div className={`${classes.boxRight} ${classes.rightTop}`}>
                    <Typography variant="h2" component="h2" style={{ fontWeight: "700" }}>
                        Custom Stickers
                    </Typography>

                    <Typography variant="h3" component="h2" style={{ fontWeight: "700", color: "#707070", paddingTop: "10px" }}>
                        Create your own custom stickers or choose from a wide assortment of pre-made stickers.
                    </Typography>

                    <div>
                        <Grid container spacing={3} style={{paddingTop: "30px"}}>
                            <Grid item xs={6} sm={6} lg={3} xl={3}>
                                <img src="images/sticker-1.jpg" alt="Custom Stickers" style={{width: "100px", height: "100px"}} />
                            </Grid>

                            <Grid item xs={6} sm={6} lg={3} xl={3}>
                                <img src="images/sticker-2.jpg" alt="Custom Stickers" style={{width: "100px", height: "100px"}} />
                            </Grid>

                            <Grid item xs={6} sm={6} lg={3} xl={3}>
                                <img src="images/sticker-3.jpg" alt="Custom Stickers" style={{width: "100px", height: "100px"}} />
                            </Grid>

                            <Grid item xs={6} sm={6} lg={3} xl={3}>
                                <img src="images/sticker-4.jpg" alt="Custom Stickers" style={{width: "100px", height: "100px"}} />
                            </Grid>
                        </Grid>
                    </div>

                    <div style={{padding: "10px"}}>
                        {/* <Button className={classes.homeButton}>Shop Now</Button> */}
                    </div>
                </div>

                <div className={`${classes.boxRight} ${classes.rightBottom}`}>
                    <Typography variant="h2" component="h2" style={{ fontWeight: "700" }}>
                        View Our Products
                    </Typography>

                    <Typography variant="h3" component="h2" style={{ fontWeight: "700", color: "#707070", paddingTop: "10px" }}>
                        Use these links to view our products while our website is being built.
                    </Typography>

                    <div style={{padding: "10px"}}>
                        <Grid container spacing={3}>
                            <Grid item>
                                <Link href="https://www.instagram.com/sarcasmandgiggles/" target="_blank" rel="noreferrer">
                                    <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                                        <InstagramIcon fontSize="large" color="primary" />
                                        <span>Instagram</span>
                                    </div> 
                                </Link>
                            </Grid>

                            <Grid item>
                                <Link href="https://www.facebook.com/sarcamandgiggles" target="_blank" rel="noreferrer">
                                    <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>                                      
                                        <FacebookIcon fontSize="large" color="primary" />
                                        <span>Facebook</span>
                                    </div> 
                                </Link>
                            </Grid>

                            <Grid item>
                                <Link href="https://www.tiktok.com/@sarcasmandgiggles?lang=en" target="_blank" rel="noreferrer"> {/*onClick={preventDefault} */}
                                    <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                                        <MusicNoteIcon fontSize="large" color="primary" />
                                        <span>TikTok</span>
                                    </div> 
                                </Link>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Grid>
        </Grid>
    </Container>
  );
};

export default MainView;
