import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useRoutes } from 'react-router-dom';
import routes from 'src/routes';
import {
  AppBar,
  Box,
  Hidden,
  Toolbar,
  IconButton,
  Typography
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import theme from 'src/theme';

import './App.css';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    paddingRight: "20px"
  },
  appBar: {
    backgroundColor: "#8493AF !important",
    color: "#ffffff",
    height: "60px"
  }
}));

function App() {
  const classes = useStyles();
  const routing = useRoutes(routes());

  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <div className={classes.root} style={{ width: '100%' }}>
          <div className="app-name-font app-name-main">
            Sarcasm &amp; Giggles
          </div>

          <AppBar position="static" className={classes.appBar}>
            <Toolbar>
              {/* <Box display="flex" justifyContent="flex-end" p={1} m={1}>
                <Hidden lgUp>
                  <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                    <MenuIcon />
                  </IconButton>
                </Hidden>
              </Box>

              <Typography variant="h6" className={classes.title}>
                Our Story
              </Typography>

              <Typography variant="h6" className={classes.title}>
                Contact Us
              </Typography> */}
              We are currently building our website. You can find the links below to see our products on social media.
            </Toolbar>
          </AppBar>

          {routing}
        </div>
      </ThemeProvider>
    </React.StrictMode>
  );
}

export default App;
