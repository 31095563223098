import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
//import { recoilPersist } from 'recoil-persist'; //use in atom only now: https://github.com/polemius/recoil-persist
import { ThemeProvider } from '@material-ui/core';
import App from './App';
import theme from 'src/theme';
import GlobalStyles from 'src/components/GlobalStyles';
import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <React.Suspense fallback={<div>Loading...</div>}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <GlobalStyles />
            <App />
          </ThemeProvider>
        </BrowserRouter>
      </React.Suspense>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root')
);
