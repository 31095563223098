/* eslint-disable */

export default {
  h1: {
    fontWeight: 500,
    fontSize: 30,
    letterSpacing: '-0.24px',
    '@media (max-width:800px)': {
      fontSize: '1.5rem',
    }
  },
  h2: {
    fontWeight: 500,
    fontSize: 25,
    letterSpacing: '-0.24px',
    '@media (max-width:800px)': {
      fontSize: '1.0rem',
    }
  },
  h3: {
    fontWeight: 500,
    fontSize: 18,
    letterSpacing: '-0.06px',
    '@media (max-width:800px)': {
      fontSize: '0.75rem',
    }
  },
  h4: {
    fontWeight: 500,
    fontSize: 20,
    letterSpacing: '-0.06px'
  },
  h5: {
    fontWeight: 500,
    fontSize: 16,
    letterSpacing: '-0.05px'
  },
  h6: {
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: '-0.05px'
  },
  overline: {
    fontWeight: 500
  }
};